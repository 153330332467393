<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Hiển thị</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>mục</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Tìm kiếm..."
              />
              <b-button :to="{ name: 'add-inventory' }" variant="primary">
                <span class="text-nowrap">Thêm Kho</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay
        :show="showTable||isLoading"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refInventoryTable"
          class="position-relative"
          :items="fetchPharmaInventories"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Không có dữ liệu hiển thị"
          :sort-desc.sync="isSortDirDesc"
          @row-clicked="data=>showDetail(data.id)"
          tbody-class="cursor-pointer"
        >
          <!-- Column: Title -->
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <b-link
                :to="{ name: 'detail-product', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >{{ data.item.title }}</b-link>
            </b-media>
          </template>

          <!-- Column: CreatedAt -->
          <template #cell(created_at)="data">
            <span class="text-capitalize">{{ formatDate(data.item.created_at) }}</span>
          </template>
          <template #cell(managerId)="data">
            <span
              class="text-capitalize"
            >{{data.item.manager_info&&data.item.manager_info.name||data.item.manager_info.username }}</span>
          </template>
          <!-- Column: Actions -->

          <!-- <template #cell(action)="data">
            <div class="d-flex justify-content-end cursor-pointer">
              <feather-icon
                icon="EditIcon"
                size="22"
                class="text-primary mr-2"
                @click="showDetail(data.item.id)"
              />
              <feather-icon
                icon="Trash2Icon"
                size="22"
                class="text-danger"
                @click.stop="deleteInventory(data.item.id)"
              />
            </div>
          </template>-->
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Hiển thị {{ dataMeta.from }} đến {{ dataMeta.to }} của {{ dataMeta.of }}
              mục
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalInventories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BFormCheckbox,
  BBadge,
  BPagination,
  BOverlay
} from "bootstrap-vue";
// import TagsListAddNew from '@/components/tagManager/TagsListAddNew.vue'
// import TagsListEdit from '@/components/tagManager/TagsListEdit.vue'
import moment from "moment";
import vSelect from "vue-select";
// eslint-disable-next-line import/no-cycle
import { ref } from "@vue/composition-api";
// eslint-disable-next-line import/no-cycle
import { avatarText } from "@core/utils/filter";
// eslint-disable-next-line import/no-cycle
import useProductsList from "./inventoryManager";
// eslint-disable-next-line import/no-cycle
import store from "@/store";
// eslint-disable-next-line import/no-cycle
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BOverlay,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    vSelect
  },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapGetters("app", ["statusOptions"])
  },
  methods: {
    refreshTable() {
      this.showTable = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.refetchData();
        this.showTable = false;
      }, 2000);
    },
    switchIsShow(tag) {
      const payload = tag;
      // eslint-disable-next-line prefer-destructuring
      payload.is_show = !tag.is_show;
      store
        .dispatch("product/updateTags", { id: tag.id, tag: payload })
        .then(() => {
          this.refreshTable();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Update show success",
              icon: "AlertTriangleIcon",
              variant: "success"
            }
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error update show",
              icon: "AlertTriangleIcon",
              variant: "danger"
            }
          });
        });
    },
    showEditBar(data) {
      this.dataTag = {
        name: data.name,
        is_show: {
          label: this.statusOptions.find(e => e.value === data.is_show).label,
          value: data.is_show
        },
        id: data.id
      };
      this.isEditTagsSidebarActive = true;
    },
    formatDate(time) {
      return moment(time).format("HH:mm:ss DD/MM/YYYY");
    },
    deleteInventory(id) {
      this.$bvModal
        .msgBoxConfirm("Bạn có chắc chắn muốn xoá Kho này không?", {
          title: "Xác nhận xoá Kho",
          size: "sm",
          okVariant: "primary",
          okTitle: "Xác nhận",
          cancelTitle: "Huỷ",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true
        })
        .then(async value => {
          if (value) {
            try {
              this.isLoading = true;
              await this.$store.dispatch(
                "inventoryManager/deleteInventory",
                id
              );

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Xoá Kho thành công",
                  icon: "CheckCircleIcon",
                  variant: "success"
                }
              });
            } catch (error) {
              console.log(error);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Có lỗi xảy ra khi xoá Kho",
                  icon: "AlertTriangleIcon",
                  variant: "danger"
                }
              });
            } finally {
              this.isLoading = false;
            }
          }
        });
    },
    showDetail(id) {
      if (!id) return;
      this.$router.push({
        name: "update-inventory",
        params: {
          id
        }
      });
    }
  },

  setup() {
    const dataTag = ref({});
    const {
      timeout,
      showTable,
      fetchPharmaInventories,
      tableColumns,
      perPage,
      currentPage,
      totalInventories,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInventoryTable,
      refetchData
    } = useProductsList();
    return {
      timeout,
      showTable,
      // Sidebar
      dataTag,
      fetchPharmaInventories,
      tableColumns,
      perPage,
      currentPage,
      totalInventories,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInventoryTable,
      refetchData,

      // Filter
      avatarText

      // Extra Filters
    };
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
